.jobs-main-root {
  padding: 100px 0 0;
}

.jobs-sub-root {
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.embed-screen {
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
}
